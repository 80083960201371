@use '../../themes/sizes' as *;
@use '../../themes/font-weights' as *;
@use '../../themes/mixins' as *;
@use '../../themes/colors' as *;

.task-group {
  @include flex-column;
  height: $size-full;

  &__table {
    @include flex-1;
    margin-top: $size-16;

    .delete-icon {
      cursor: pointer;
    }
  }
}
