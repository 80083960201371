@use '../../themes/font-weights' as *;
@use '../../themes/sizes' as *;
@use '../../themes/mixins' as *;
@use '../../themes/colors' as *;

.task {
  @include flex-column;
  height: $size-full;

  &__table {
    @include flex-1;
    margin-top: $size-16;

    .icons {
      @include flex-center-vert;

      &__add-child {
        cursor: pointer;

        &--hidden {
          visibility: hidden;
        }
      }

      &__edit,
      &__delete {
        cursor: pointer;
        margin-left: $size-8;
      }
    }
  }
}
