@use '../../../../themes/mixins' as *;
@use '../../../../themes/sizes' as *;
@use '../../../../themes/colors' as *;
@use '../../../../themes/font-weights' as *;

.task-modal {
  .parent-task {
    margin-bottom: $size-16;

    &__name {
      display: inline-block;
      font-weight: $font-weight-500;
    }
  }

  .active {
    @include flex-center-vert-between;
    margin-top: $size-24;
    margin-bottom: $size-8;

    &__text {
      margin-right: $size-8;

      &--required {
        color: $color-light-red;
      }
    }
  }
}
