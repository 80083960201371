@use '../../../../themes/sizes' as *;
@use '../../../../themes/mixins' as *;
@use '../../../../themes/colors' as *;
@use '../../../../themes/font-weights' as *;

.tasks {
  margin-top: $size-16;

  &__header {
    @include flex-center-vert-between;
    background-color: $color-white;
    border-radius: $size-8 $size-8 0 0;
    padding: $size-16 $size-24;
    border-bottom: $size-1 solid $color-soft-peach;

    &-label {
      font-weight: $font-weight-700;
    }

    &-input {
      width: 40%;
    }
  }

  &__table {
    .ant-table {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
}
