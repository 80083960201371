@use '../../themes/sizes' as *;
@use '../../themes/colors' as *;
@use '../../themes/mixins' as *;
@use '../../themes/font-weights' as *;

.textarea {
  &__label {
    @include flex-center-vert;
    margin-bottom: $size-4;

    &-text {
    }

    &-required {
      display: inline-block;
      margin-left: $size-4;
      color: $color-light-red;
      font-size: $size-12;
    }
  }

  &__main {
  }

  &__error {
    font-size: $size-12;
    color: $color-light-red;
    margin-top: $size-2;
  }
}
